import * as THREE from 'three'
import React, {useEffect, useRef, useState} from 'react'
import { useGLTF } from '@react-three/drei'
import { GLTF } from 'three-stdlib'
import {ItemProps} from "../../types/ItemProps";
import {useXR} from "@react-three/xr";

const ITEM_URI = `${process.env.REACT_APP_ASSETS_URL}/heather2-transformed.glb`;

type GLTFResult = GLTF & {
  nodes: {
    relic2: THREE.Mesh
  }
  materials: {}
}

type ContextType = Record<string, React.ForwardRefExoticComponent<JSX.IntrinsicElements['mesh']>>

export function Heather2({ scale = 1, position = [0,0,0], xrScaleOffset = 1, xrPositionOffset = [0,-5,-5] }: ItemProps) {
  const { nodes, materials } = useGLTF(ITEM_URI, 'https://www.gstatic.com/draco/versioned/decoders/1.4.1/') as GLTFResult

  const group = useRef<THREE.Group>(null)

  // console.log('Test %cTest', 'color: goldenrod; font-size: 16px;')
  // console.log(nodes, materials)

  const {
    isPresenting
  } = useXR();

  const [localScale, setLocalScale] = useState(scale);
  const [localPosition, setLocalPosition] = useState(position);

  useEffect(() => {
    console.log(`Is Presenting is: ${isPresenting}`);
    if (isPresenting) {
      setLocalScale(scale*xrScaleOffset);
      setLocalPosition(xrPositionOffset);
    } else {
      setLocalScale(scale)
      setLocalPosition(position)
    }
  }, [isPresenting]);


  return (
    <group ref={group} dispose={null} scale={localScale} position={localPosition}>
      <mesh castShadow receiveShadow geometry={nodes.relic2.geometry} material={nodes.relic2.material} rotation={[0,3,0]}>
        <meshStandardMaterial color="white" metalness={0} roughness={5} shadowSide={THREE.DoubleSide} />
      </mesh>
    </group>
  )
}
