import React, {useEffect, useState} from 'react';
import {
  Route, Routes, useLocation
} from "react-router-dom";
import './App.scss';
import NavBar from "./components/layout/NavBar";
import {CameraAltOutlined, ChevronLeft, ChevronRight, InfoOutlined, Menu} from "@mui/icons-material";
import PhotoViewer from "./components/layout/PhotoViewer";
import InfoModal from "./components/layout/InfoModal";
import LeftSideDrawer from "./components/layout/LeftSideDrawer";
import HomeScreen from "./components/pages/HomeScreen";
import DashboardScreen from "./components/pages/DashboardScreen";
import RelicOne from "./components/pages/RelicOne";
import RightSideDrawer from "./components/layout/RightSideDrawer";
import {Gltf} from "@react-three/drei";
import {Heather2} from "./components/3d/Heather2";

function App() {
  const [showImages, setShowImages] = useState(false);
  const [showInfoModal, setShowInfoModal] = useState(false);
  const [showBookingModal, setShowBookingModal] = useState(false);

  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const [drawerRightOpen, setDrawerRightOpen] = React.useState(false);
  const [infoOpen, setInfoOpen] = React.useState(false);

  // TODO these aren't goint to get updated when route changes if the url is loaded directly
  const [infoTitle, setInfoTitle] = React.useState('Artefact');
  const [infoText, setInfoText] = React.useState('Thought to date from the 17th and 18th century or earlier. These artefacts were likely present in large numbers all over the United Kingdom due to their mass produced appearance. It has been suggested there was a possible religious connection, and that they were perhaps used as offerings by pilgrims or even to ward off evil spirits.');
  const [audioLink, setAudioLink] = React.useState('https://assets.unegma.net/web4.tours/heather2.wav');

  const location = useLocation();

  useEffect(() => {
    setTimeout(() => {
      if (location.pathname === '/') {
        setDrawerOpen(true);
      }
    }, 1000);
  }, [])


  const toggleLeftSideDrawer = (event: React.KeyboardEvent | React.MouseEvent) => {
      if (event.type === 'keydown' && (
        (event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift'))
      {
        return;
      }
      setDrawerOpen(!drawerOpen);
  };
  const toggleRightSideDrawer = (event: React.KeyboardEvent | React.MouseEvent) => {
      if (event.type === 'keydown' && (
        (event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift'))
      {
        return;
      }
    setDrawerRightOpen(!drawerRightOpen);
  };

  return (
    <div className="App">
      {/*<CssBaseline /> todo add this? */}

      <NavBar toggleLeftSideDrawer={toggleLeftSideDrawer} showBookingModal={showBookingModal} setShowBookingModal={setShowBookingModal} />

      <InfoModal showInfoModal={showInfoModal} setShowInfoModal={setShowInfoModal} />
      {/*<BookingModal showBookingModal={showBookingModal} setShowBookingModal={setShowBookingModal} />*/}
      <PhotoViewer showImages={showImages} />

      <LeftSideDrawer
        drawerOpen={drawerOpen}
        toggleLeftSideDrawer={toggleLeftSideDrawer}
        setShowImages={setShowImages}
        setShowInfoModal={setShowInfoModal}
        setInfoTitle={setInfoTitle}
        setInfoText={setInfoText}
        setAudioLink={setAudioLink}
      />

      <RightSideDrawer
        infoOpen={infoOpen}
        toggleRightSideDrawer={toggleRightSideDrawer}
        // setShowImages={setShowImages}
        // setShowInfoModal={setShowInfoModal}
        // showInfoModal={showInfoModal}
        infoTitle={infoTitle}
        infoText={infoText}
        drawerRightOpen={drawerRightOpen}
        setDrawerRightOpen={setDrawerRightOpen}
        audioLink={audioLink}
      />

      <Routes>
        <Route
          key={'home'}
          path="/"
          element={
            <HomeScreen toggleLeftSideDrawer={toggleLeftSideDrawer}/>
          }
        />

        <Route
          key={'dashboard'}
          path="/dashboard"
          element={
            <DashboardScreen />
          }
        />

        <Route
          key={'relic'}
          path="/relic"
          element={
            <RelicOne rotationLock={false} cameraPosition={[-2,2,-2]} minDistance={20} maxDistance={30} relic={
              // <Gltf src={`${process.env.REACT_APP_ASSETS_URL}/heather2-transformed.glb`} />
              <Heather2 />
            }/>
          }
        />

        {/*<Route*/}
        {/*  key={'chanel'}*/}
        {/*  path="/chanel"*/}
        {/*  element={*/}
        {/*    <RelicOne rotationLock={false} cameraPosition={[-10,10,-10]} minDistance={5} maxDistance={10} relic={*/}
        {/*      <Chanel scale={0.1}/>}*/}
        {/*    />*/}
        {/*  }*/}
        {/*/>*/}

        <Route
          path="*"
          element={
            <main style={{ padding: "1rem" }}>
              <p>There's nothing here!</p>
            </main>
          }
        />
      </Routes>

      <div className="buttons-container">
        {/*<InfoOutlined className="pointer" style={{ color: "black", margin: "0 4px" }} onClick={() => {setShowInfoModal(!showInfoModal)}}/>*/}

        <div className="pointer" onClick={(event) => {toggleRightSideDrawer(event)}}>
          <InfoOutlined className="pointer" style={{ color: "black", margin: "0 4px" }} />
          { drawerRightOpen && (
            <ChevronRight style={{ color: "black", margin: "0 4px" }} />
          )}
          { !drawerRightOpen && (
            <ChevronLeft style={{ color: "black", margin: "0 4px" }} />
          )}
        </div>
      </div>
    </div>
  );
}

export default App;
